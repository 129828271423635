const eventMixin = {
  dispatchEvent(...args) {
    return this.eventElement.dispatchEvent(...args)
  },

  addEventListener(...args) {
    return this.eventElement.addEventListener(...args)
  },

  addEventListeners(...args) {
    let callback
    let options

    const last = args.pop()
    if (typeof last === "function") {
      callback = last
    } else {
      options = last
      callback = args.pop()
    }

    const eventTypes = args
    eventTypes.forEach((type) => this.addEventListener(type, callback, options))
  },

  fire(name, data) {
    const fullName = this.constructor.events[name]
    const event = new CustomEvent(fullName, {
      bubbles: true,
      cancelable: true,
      detail: data
    })
    this.dispatchEvent(event)
    return !event.defaultPrevented
  },

  on(name, ...args) {
    const fullName = this.constructor.events[name]
    return this.addEventListener(fullName, ...args)
  }
}

export default eventMixin
