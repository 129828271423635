// Returns Card billing details object:
// https://stripe.com/docs/api/payment_methods/create#create_payment_method-billing_details
//
// - All fields are optional.
// - 'name' is strongly recommended.
// - 'address country' is strongly recommended for Stripe Tax support.

import CardAddressDetails from "./address/details"
import eventMixin from "../eventMixin"

class CardDetails {
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        name: "[data-billing-card-name]",
      }
    }
    return this._selectors
  }

  static get events() {
    if (this._events === undefined) {
      this._events = {
        change: "billing:card:details:change"
      }
    }
    return this._events
  }

  constructor(card) {
    this.card = card
    this.eventElement = this.card.element
    this.parent = this.card.parent
    this.address = new CardAddressDetails(this.card)

    this.init()
  }

  init() {
    if (this.nameElement) {
      this.nameElement.addEventListener("input", (event) => {
        this.handleChange({name: event.target.value})
      })
    }

    this.address.on("change", (event) => {
      this.handleChange({address: event.detail})
    })
  }

  toObject() {
    const object = {}

    if (this.name) {
      object.name = this.name
    }

    const address = this.address.toObject()
    if (Object.keys(address).length !== 0) {
      object.address = address
    }

    return object
  }

  get nameElement() {
    if (this._nameElement === undefined) {
      this._nameElement =
        this.parent.querySelector(this.constructor.selectors.name)
    }
    return this._nameElement
  }

  get name() {
    if (this.nameElement) {
      return this.nameElement.value
    }
  }

  handleChange(data) {
    this.fire("change", data)
  }
}

Object.defineProperties(
  CardDetails.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

export default CardDetails
