import eventMixin from "../../eventMixin"

class CardAddressDetails {
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        city: "[data-billing-card-address='city']",
        country: "[data-billing-card-address='country']",
        line1: "[data-billing-card-address='line1']",
        line2: "[data-billing-card-address='line2']",
        zip: "[data-billing-card-address='zip']",
        state: "[data-billing-card-address='state']"
      }
    }
    return this._selectors
  }

  static get events() {
    if (this._events === undefined) {
      this._events = {
        change: "billing:card:address:details:change"
      }
    }
    return this._events
  }

  constructor(card) {
    this.card = card
    this.eventElement = this.card.element
    this.parent = this.card.parent
    this.cardZip = null

    this.init()
  }

  init() {
    // By default 'zip' is entered to card element.
    this.card.stripeElement.on("change", (event) => {
      if (event.value) {
        const postalCode = event.value.postalCode
        if (postalCode.length === 0) {
          // Empty string unsets 'cardZip' value.
          this.cardZip = null
        } else {
          this.cardZip = postalCode
        }

        this.handleChange({zip: this.cardZip})
      }
    })

    if (this.cityElement) {
      this.cityElement.addEventListener("input", (event) => {
        this.handleChange({city: event.target.value})
      })
    }

    if (this.countryElement) {
      this.countryElement.addEventListener("input", (event) => {
        this.handleChange({country: event.target.value})
      })
    }

    if (this.line1Element) {
      this.line1Element.addEventListener("input", (event) => {
        this.handleChange({line1: event.target.value})
      })
    }

    if (this.line2Element) {
      this.line2Element.addEventListener("input", (event) => {
        this.handleChange({line2: event.target.value})
      })
    }

    if (this.zipElement) {
      this.zipElement.addEventListener("input", (event) => {
        this.handleChange({zip: event.target.value})
      })
    }

    if (this.stateElement) {
      this.stateElement.addEventListener("input", (event) => {
        this.handleChange({state: event.target.value})
      })
    }
  }

  toObject() {
    const object = {}

    if (this.city) {
      object.city = this.city
    }
    if (this.country) {
      object.country = this.country
    }
    if (this.line1) {
      object.line1 = this.line1
    }
    if (this.line2) {
      object.line2 = this.line2
    }
    if (this.zip || this.cardZip) {
      // Standalone 'zip' element is prioritized.
      object.postal_code = this.zip || this.cardZip
    }
    if (this.state) {
      object.state = this.state
    }

    return object
  }

  get cityElement() {
    if (this._cityElement === undefined) {
      this._cityElement =
        this.parent.querySelector(this.constructor.selectors.city)
    }
    return this._cityElement
  }

  get city() {
    if (this.cityElement) {
      return this.cityElement.value
    }
  }

  get countryElement() {
    if (this._countryElement === undefined) {
      this._countryElement =
        this.parent.querySelector(this.constructor.selectors.country)
    }
    return this._countryElement
  }

  get country() {
    if (this.countryElement) {
      return this.countryElement.value
    }
  }

  get line1Element() {
    if (this._line1Element === undefined) {
      this._line1Element =
        this.parent.querySelector(this.constructor.selectors.line1)
    }
    return this._line1Element
  }

  get line1() {
    if (this.line1Element) {
      return this.line1Element.value
    }
  }

  get line2Element() {
    if (this._line2Element === undefined) {
      this._line2Element =
        this.parent.querySelector(this.constructor.selectors.line2)
    }
    return this._line2Element
  }

  get line2() {
    if (this.line2Element) {
      return this.line2Element.value
    }
  }

  get zipElement() {
    if (this._zipElement === undefined) {
      this._zipElement =
        this.parent.querySelector(this.constructor.selectors.zip)
    }
    return this._zipElement
  }

  get zip() {
    if (this.zipElement) {
      return this.zipElement.value
    }
  }

  get stateElement() {
    if (this._stateElement === undefined) {
      this._stateElement =
        this.parent.querySelector(this.constructor.selectors.state)
    }
    return this._stateElement
  }

  get state() {
    if (this.stateElement) {
      return this.stateElement.value
    }
  }

  handleChange(data) {
    this.fire("change", data)
  }
}

Object.defineProperties(
  CardAddressDetails.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

export default CardAddressDetails
