// Related helper: Billing::Helper#billing_omni_form_with.
import { put } from "@rails/request.js"
import CardForm from "./cardForm"
import FormAction from "./formAction"

const selector = "[data-billing-omni-form]"

class OmniForm extends CardForm {
  static init() {
    super.init(selector)
  }

  static get submitEvent() {
    if (this._submitEvent === undefined) {
      this._submitEvent = new Event("submit", {
        bubbles: true,
        cancelable: true
      })
      this._submitEvent.billingTaxAddressSet = true
    }
    return this._submitEvent
  }

  constructor(...args) {
    super(...args)
    this.action = new FormAction(this)

    this.turboSubmitting = false
    this.turboStreamRenderHandled = false
  }

  init() {
    super.init()

    if (this.isNative()) {
      if (this.isTurboLoaded()) {
        throw new Error(
          "Billing omni form must use turbo. To make it work " +
          "remove 'data-turbo=\"false\"' attribute from the form element."
        )
      } else if (this.isRailsUjsLoaded()) {
        throw new Error(
          "Billing omni form must use rails-ujs. To make it work " +
          "add 'data-remote=\"true\"' attribute to the form element."
        )
      } else {
        throw new Error("Billing omni form must use turbo or rails-ujs.")
      }
    }

    if (this.taxDefaultStrategy) {
      // Works with turbo and rails-ujs.
      this.addEventListener("submit", (event) => {
        if (event.billingTaxAddressSet) {
          // Tax address is set - allow form submit.
          return
        }
        if (this.card.hidden) {
          // Scenarios:
          // - Free plan - customer IP address is used for tax.
          // - Card already exists - card details used for tax.
          return
        }
        event.preventDefault()
        event.stopPropagation()

        this.handleSubmit()
        this.setTaxAddress()
          .then(() => {
            this.submitAfterSetTaxAddress()
          })
          .catch((error) => {}) // noop
      }, { capture: true })

      // Scenario:
      // - Using tax default strategy, but card is hidden.
      // - Skip setting tax address.
      // - Disable form elements (via handleSubmit()) after form data is sent.
      this.addEventListener("turbo:submit-start", () => {
        this.turboSubmitting = true
        if (this.card.hidden) {
          this.handleSubmit()
        }
      })
      this.addEventListener("ajax:send", () => {
        if (this.card.hidden) {
          this.handleSubmit()
        }
      })

    } else {
      this.addEventListener("turbo:submit-start", () => {
        this.turboSubmitting = true
        this.handleSubmit()
      })
      this.addEventListener("ajax:send", () => {
        this.handleSubmit()
      })
    }

    // turbo events
    // "turbo:before-stream-render" triggered before "turbo:submit-end"
    // (common scenario).
    document.addEventListener("turbo:before-stream-render", (event) => {
      if (this.turboSubmitting) {
        this.turboStreamRenderHandled = true
        const originalRender = event.detail.render
        let success
        let rendered

        const nextStepIfPossible = () => {
          if (rendered) {
            if (success === true) {
              this.handleResponse()
            } else if (success === false){
              this.handleError()
            }
            // 'success' is undefined, try again after 'turbo:submit-end'.
          }
          // Try again after turbo action is rendered in the DOM.
        }

        // This scenario is complex.
        //
        // - Function assigned to 'event.detail.render' only starts executing
        //   after a while, see:
        //   https://github.com/hotwired/turbo/blob/f88bfe45ed44abb6ea9140a8ba138dfe68aa2730/src/elements/stream_element.js#L47-L50
        // - The 'originalRender' function is async.
        //
        // 'turbo:submit-end' can run at any point in time:
        //
        // - Between now and when 'event.detail.render' starts executing.
        // - While 'originalRender' function runs.
        // - After 'originalRender' function finishes.

        this.addEventListener("turbo:submit-end", (event) => {
          success = event.detail.success

          nextStepIfPossible()
        }, { once: true })

        event.detail.render = (...args) => {
          originalRender(...args)
          rendered = true

          nextStepIfPossible()
        }
      }
    })

    // Handles cases:
    // - "turbo:frame-render" (always triggers after "turbo:submit-end").
    // - "turbo:submit-end" triggered before "turbo:before-stream-render"
    //   (rare, but does happen).
    this.addEventListener("turbo:submit-end", (event) => {
      this.turboSubmitting = false

      if (this.turboStreamRenderHandled) {
        // "turbo:before-stream-render" triggered before "turbo:submit-end".
        this.turboStreamRenderHandled = false
        return
      }

      if (event.detail.success) {
        // Unfortunately, turbo emits the 'turbo:submit-end' event *before*
        // it performs frame replace (and sometimes also stream render).
        // So now, wait on those.
        document.addEventListener("turbo:frame-render", (event) => {
          this.handleResponseOnTurboFrameRender(event)
        }, { once: true })

        document.addEventListener("turbo:before-stream-render", (event) => {
          this.handleResponseOnTurboStreamRender(event)
        }, { once: true })

      } else {
        this.handleError()
      }
    }, { capture: true })

    // rails-ujs events
    this.addEventListener("ajax:success", (event) => {
      this.handleResponse()
    })

    this.addEventListener("ajax:error", () => {
      this.handleError()
    })
  }

  setTaxAddress() {
    return new Promise((resolve, reject) => {
      put("/billing/tax/address", {
        body: {address: this.card.details.address.toObject()},
        contentType: "application/json"
      }).then((response) => {
        if (response.ok) {
          resolve()
        } else if (response.unprocessableEntity) {
          response.json.then((json) => {
            const message = json.error
            this.handleCardError(message)
            reject(message)
          })
        } else {
          const status = response.statusCode
          // This error is handled in 'catch' below.
          throw new Error(
            `Billing tax address: invalid server response status ${status}`
          )
        }
      })
      .catch((error) => {
        const message = this.card.wrapper.dataset.billingTaxAddressError
        this.handleCardError(message)
        reject(message)
      })
    })
  }

  // This form submit skips 'setTaxAddress'.
  submitAfterSetTaxAddress() {
    // Problem: disabled form elements won't be submitted to the server.
    // Solution: temporarily enable elements, then disable them again after
    // data is sent.
    this._enableElements()
    this.addEventListeners("turbo:submit-start", "ajax:send", () => {
      this._disableElements()
    }, { once: true })

    // Works with turbo and rails-ujs.
    this.dispatchEvent(this.constructor.submitEvent)
  }

  handleResponse() {
    const response = this.action.extract()
    const status = response.status

    if (status === "success") {
      this.handleSuccess()
      window.location = response.redirect_path

    } else if (status === "pending") {
      const require = response.require
      if (require === "payment_method") {
        this.requirePaymentMethod(response)

      } else if (require === "confirmation") {
        this.requireConfirmation(response)

      } else {
        throw new Error(`invalid response require value '${require}'`)
      }

    } else {
      throw new Error(`invalid response status '${status}'`)
    }
  }

  handleResponseOnTurboFrameRender = (event) => {
    // Turbo frame is rendered in the DOM at this point.
    // Cleanup unused 'turbo stream render' callback.
    document.removeEventListener(
      "turbo:before-stream-render",
      this.handleResponseOnTurboStreamRender
    )

    this.handleResponse()
  }

  handleResponseOnTurboStreamRender = (event) => {
    const originalRender = event.detail.render
    event.detail.render = (...args) => {
      // Cleanup unused 'turbo frame render' callback.
      document.removeEventListener(
        "turbo:frame-render",
        this.handleResponseOnTurboFrameRender
      )
      originalRender(...args)
      // Turbo stream action is now rendered in the DOM.
      this.handleResponse()
    }
  }

  requirePaymentMethod(response) {
    const {
      client_secret,
      verify_path
    } = response

    this.card.show()
    this.card.submit(client_secret)
      .then(() => {
        this.handleSuccess()
        window.location = verify_path
      })
      .catch(() => {
        this.handleError()
      })
  }

  requireConfirmation(response) {
    const {
      client_secret,
      payment_method,
      verify_path
    } = response

    this.card.confirm(client_secret, payment_method)
      .then(() => {
        this.handleSuccess()
        window.location = verify_path
      })
      .catch(() => {
        this.handleError()
      })
  }

  get taxDefaultStrategy() {
    return this.card.wrapper.dataset.billingTaxDefaultStrategy
  }

  handleCardError(message) {
    // Function invocation order for the below function matters:
    // 1. handleError() enables all form elements.
    // 2. card.error.set() disables form submit element until error is fixed.
    //
    // If the order is flipped form submit element won't be disabled at the end.
    this.handleError()
    this.card.error.set(message)
  }
}

document.addEventListener("DOMContentLoaded", (event) => OmniForm.init())
document.addEventListener("turbo:load", (event) => OmniForm.init())
