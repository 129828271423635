import Form from "./form"
import Card from "./card"

class CardForm extends Form {
  constructor(...args) {
    super(...args)
  }

  init() {
    super.init()
    this.card = new Card(this.element)

    this.card.error.on("set", () => {
      this.disableSubmit()
    })
    this.card.error.on("clear", () => {
      this.enableSubmit()
    })
    this.card.on("hide", () => {
      if (this.card.error.isSet) {
        this.enableSubmit()
      }
    })
    this.card.on("show", () => {
      if (this.card.error.isSet) {
        this.disableSubmit()
      }
    })
  }

  handleSubmit() {
    this.card.disable()
    super.handleSubmit()
  }

  // handleSuccess() doesn't enable 'this.card' - card data is already
  // submitted remotely, user is waiting on a page redirect.

  // This handler should not display errors. That's done elsewhere:
  // - Host app logic should display form errors unrelated to billing.
  // - Card.error.set() displays card errors.
  handleError() {
    this.card.enable()
    super.handleError()
  }
}

export default CardForm
