const matches = Element.prototype.matches ||
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector

const elementMatches = (element, selector) => {
  while (element instanceof Element && !matches.call(element, selector)) {
    element = element.parentNode
  }

  if (element instanceof Element) {
    // element matches selector
    return element
  } else {
    return null
  }
}

export { elementMatches as default }
