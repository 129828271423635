import eventMixin from "../eventMixin"

class CardContainer {
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        element: "[data-billing-card]"
      }
    }
    return this._selectors
  }

  static get events() {
    if (this._events === undefined) {
      this._events = {
        show: "billing:card:container:show",
        hide: "billing:card:container:hide"
      }
    }
    return this._events
  }

  constructor(parent) {
    this.parent = parent // form element
    this.element = this.parent.querySelector(this.constructor.selectors.element)
    this.eventElement = this.element

    // Container is hidden when the default plan is free.
    // Note that even if 'container' is visible, CardWrapper may be hidden and
    // the card will not be shown.
    this.visible = this.element.style.display !== "none"
    this.handleShowHide()
  }

  // show/hide logic
  handleShowHide() {
    this.parent.addEventListener("change", (e) => {
      // Works only with radio buttons!
      const radioButton = e.target
      if (!radioButton.checked) { return }

      if (radioButton.dataset.hasOwnProperty("billingCardShow")) {
        this.show()
      } else if (radioButton.dataset.hasOwnProperty("billingCardHide")) {
        this.hide()
      }
    })
  }

  get hidden() {
    return !this.visible
  }

  show() {
    if (this.hidden) {
      this.visible = true

      this.element.style.display = null
      this.fire("show")
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false

      this.element.style.display = "none"
      this.fire("hide")
    }
  }
}

Object.defineProperties(
  CardContainer.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

export default CardContainer
