// Related helper: Billing::Helper#billing_card_payment_form_tag.
import CardForm from "./cardForm"

const selector = "[data-billing-card-payment-form]"

class CardPaymentForm extends CardForm {
  static init() {
    super.init(selector)
  }

  constructor(...args) {
    super(...args)
  }

  init() {
    super.init()

    if (!this.isNative()) {
      if (this.isTurbo()) {
        throw new Error(
          "Billing card payment form must *not* use turbo. To make it work " +
          "add 'data-turbo=\"false\"' attribute to the form element."
        )
      } else if (this.isRailsUjs()){
        throw new Error(
          "Billing card payment form must *not* use rails-ujs. To make it " +
          "work remove 'data-remote' attribute altogether, or use " +
          "'data-remote=\"false\"' attribute with the form."
        )
      }
    }

    this.addEventListener("submit", (event) => {
      event.preventDefault()

      this.handleSubmit()
      const clientSecret = this.card.wrapper.dataset.billingClientSecret

      this.card.submit(clientSecret)
        .then(() => {
          this.handleSuccess()
          const verifyPath = this.card.wrapper.dataset.billingVerifyPath

          window.location = verifyPath
        })
        .catch(() => {
          this.handleError()
        })
    })
  }
}

document.addEventListener("DOMContentLoaded", (event) => CardPaymentForm.init())
document.addEventListener("turbo:load", (event) => CardPaymentForm.init())
