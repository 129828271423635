// Related helper: Billing::Helper#billing_error.
import eventMixin from "./eventMixin"

class BillingError {
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        element: "[data-billing-error]",
        wrapperElement: "[data-billing-error-wrapper]"
      }
    }
    return this._selectors
  }

  static get events() {
    if (this._events === undefined) {
      this._events = {
        set: "billing:error:set",
        clear: "billing:error:clear"
      }
    }
    return this._events
  }

  constructor(parent) {
    this.parent = parent // form element

    // Note: elements may not exist!
    this.element = this.parent.querySelector(this.constructor.selectors.element)
    this.wrapperElement =
      this.parent.querySelector(this.constructor.selectors.wrapperElement)
    this.eventElement = this.element

    this.isSet = false
    this.visible = false
  }

  set(message) {
    this.isSet = true

    if (this.element) {
      this.element.textContent = message
    }
    this.show()
    this.fire("set")
  }

  clear() {
    if (this.isSet) {
      this.isSet = false

      this.hide()
      if (this.element) {
        this.element.textContent = ""
      }
      this.fire("clear")
    }
  }

  get hidden() {
    return !this.visible
  }

  show() {
    if (this.isSet && this.hidden && this.wrapperElement) {
      this.visible = true

      this.wrapperElement.style.display = null
    }
  }

  hide() {
    if (this.visible && this.wrapperElement) {
      this.visible = false

      this.wrapperElement.style.display = "none"
    }
  }
}

Object.defineProperties(
  BillingError.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

export default BillingError
