// Related helper: Billing::Helper#billing_card_setup_form_tag.
import { post } from "@rails/request.js"
import CardForm from "./cardForm"

const selector = "[data-billing-card-setup-form]"

class CardSetupForm extends CardForm {
  static init() {
    super.init(selector)
  }

  constructor(...args) {
    super(...args)
  }

  init() {
    super.init()

    if (!this.isNative()) {
      if (this.isTurbo()) {
        throw new Error(
          "Billing card setup form must *not* use turbo. To make it work " +
          "add 'data-turbo=\"false\"' attribute to the form element."
        )
      } else if (this.isRailsUjs()){
        throw new Error(
          "Billing card setup form must *not* use rails-ujs. To make it " +
          "work remove 'data-remote' attribute altogether, or use " +
          "'data-remote=\"false\"' attribute with the form."
        )
      }
    }

    this.addEventListener("submit", (event) => {
      event.preventDefault()

      this.handleSubmit()
      const clientSecret = this.card.wrapper.dataset.billingClientSecret
      const operation = this.card.wrapper.dataset.billingOperation

      // See Billing::Helper#billing_card_setup for info when is each operation
      // used.
      if (operation === "authorization") {
        if (this.taxDefaultStrategy) {
          this.checkTaxAddress()
            .then(() => {
              const result = this.card.authorize(clientSecret)
              this.handleCardOperation(result)
            })
            .catch((error) => {}) // noop
        } else {
          const result = this.card.authorize(clientSecret)
          this.handleCardOperation(result)
        }

      } else if (operation === "payment") {
        const result = this.card.submit(clientSecret)
        this.handleCardOperation(result)

      } else if (operation === "setup") {
        const result = this.card.setup(clientSecret)
        this.handleCardOperation(result)

      } else {
        throw new Error(`invalid operation '${operation}'`)
      }
    })
  }

  handleCardOperation(result) {
    result.then(() => {
      this.handleSuccess()
      const verifyPath = this.card.wrapper.dataset.billingVerifyPath

      window.location = verifyPath
    })
    .catch(() => {
      this.handleError()
    })
  }

  checkTaxAddress() {
    return new Promise((resolve, reject) => {
      post("/billing/tax/address/check", {
        body: {address: this.card.details.address.toObject()},
        contentType: "application/json"
      }).then((response) => {
        if (response.ok) {
          resolve()
        } else if (response.unprocessableEntity) {
          response.json.then((json) => {
            const message = json.error
            this.handleCardError(message)
            reject(message)
          })
        } else {
          // This error is handled in 'catch' below.
          throw new Error(`Billing tax address: invalid server response status ${response.statusCode}`)
        }
      })
      .catch((error) => {
        const message = this.card.wrapper.dataset.billingTaxAddressError
        this.handleCardError(message)
        reject(message)
      })
    })
  }

  get taxDefaultStrategy() {
    return this.card.wrapper.dataset.billingTaxDefaultStrategy
  }

  handleCardError(message) {
    // Function invocation order for the below function matters:
    // 1. handleError() enables all form elements.
    // 2. card.error.set() disables form submit element until error is fixed.
    //
    // If the order is flipped form submit element won't be disabled at the end.
    this.handleError()
    this.card.error.set(message)
  }
}

document.addEventListener("DOMContentLoaded", (event) => CardSetupForm.init())
document.addEventListener("turbo:load", (event) => CardSetupForm.init())
