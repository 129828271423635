import eventMixin from "../eventMixin"

class CardWrapper {
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        element: "[data-billing-card-wrapper]"
      }
    }
    return this._selectors
  }

  static get events() {
    if (this._events === undefined) {
      this._events = {
        show: "billing:card:wrapper:show",
        hide: "billing:card:wrapper:hide"
      }
    }
    return this._events
  }

  constructor(parent) {
    this.parent = parent // form element
    this.element = this.parent.querySelector(this.constructor.selectors.element)
    this.eventElement = this.element

    this.visible = this.element.style.display !== "none"
  }

  get dataset() {
    return this.element.dataset
  }

  // show/hide logic
  get hidden() {
    return !this.visible
  }

  show() {
    if (this.hidden) {
      this.visible = true

      this.element.style.display = null
      this.fire("show")
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false

      this.element.style.display = "none"
      this.fire("hide")
    }
  }
}

Object.defineProperties(
  CardWrapper.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

export default CardWrapper
