// Related helper: Billing::Helper#billing_payment_confirmation.
import { loadStripe } from "@stripe/stripe-js"
import eventMixin from "./eventMixin"
import elementMatches from "./elementMatches"
import BillingError from "./error"

class PaymentConfirmation {
  static get selectors() {
    if (this._selectors === undefined) {
      const attr = "data-billing-payment-confirmation"
      this._selectors = {
        element: `[${attr}]`,
        wrapperElement: `[${attr}-wrapper]`,
        autoInit: `[${attr}][data-billing-auto-init]`
      }
    }
    return this._selectors
  }

  static get events() {
    return({
      start: "billing:paymentConfirmation:start",
      success: "billing:paymentConfirmation:success"
    })
  }

  static init() {
    document.querySelectorAll(this.selectors.autoInit).forEach((element) => {
      if (!element["_billing"]) {
        const paymentConfirmation = new this(element)
        element["_billing"] = paymentConfirmation
        paymentConfirmation.init()
      }
    })
  }

  constructor(element) {
    this.element = element
    this.eventElement = this.element
    this.wrapperElement =
      elementMatches(this.element, this.constructor.selectors.wrapperElement)
    this.error = new BillingError(this.wrapperElement)
  }

  init() {
    this.start()
  }

  start() {
    const publishableKey = this.element.dataset.billingPublishableKey
    if (!publishableKey) {
      throw new Error("Billing publishable key is missing")
      return
    }

    const clientSecret = this.element.dataset.billingClientSecret
    const paymentMethod = this.element.dataset.billingPaymentMethod

    this.fire("start")
    this.error.clear()

    loadStripe(publishableKey).then((stripe) => {
      // Can't use 'card.confirm()' because the use case here is not related to
      // Stripe's card input element.
      stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod
      }).then((result) => {
        if (result.error) {
          this.error.set(result.error.message)

        } else if (result.paymentIntent &&
          result.paymentIntent.status === "succeeded") {
          this.fire("success")
          const verifyPath = this.element.dataset.billingVerifyPath
          window.location = verifyPath

        } else {
          throw new Error("invalid result")
        }
      })
    })
  }
}

Object.defineProperties(
  PaymentConfirmation.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

document.addEventListener("DOMContentLoaded", (event) => {
  PaymentConfirmation.init()
})
document.addEventListener("turbo:load", (event) => {
  PaymentConfirmation.init()
})

document.addEventListener("click", (event) => {
  const element =
    elementMatches(event.target, PaymentConfirmation.selectors.element)

  if (element) {
    event.preventDefault()

    const paymentConfirmation = new PaymentConfirmation(element)
    paymentConfirmation.init()
  }
})
