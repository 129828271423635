import { loadStripe } from "@stripe/stripe-js"
import eventMixin from "./eventMixin"
import BillingError from "./error"
import CardDetails from "./card/details"
import CardWrapper from "./card/wrapper"
import CardContainer from "./card/container"

// Docs: https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options
window.billingCardElementOptions = {
  classes: {
    base: "billing-card-element-base",
    complete: "billing-card-element-complete",
    empty: "billing-card-element-empty",
    focus: "billing-card-element-focus",
    invalid: "billing-card-element-invalid",
    webkitAutofill: "billing-card-element-webkit-autofill",
    disable: "billing-card-element-disable" // not supported by stripe
  }
}

class Card {
  // TODO: webpack errors when using static property.
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        element: "[data-billing-card-element]",
        name: "[data-billing-card-name]"
      }
    }
    return this._selectors
  }

  static get events() {
    if (this._events === undefined) {
      this._events = {
        submitStart: "billing:card:submit:start",
        submitSuccess: "billing:card:submit:success",
        submitError: "billing:card:submit:error",
        confirmStart: "billing:card:confirm:start",
        confirmSuccess: "billing:card:confirm:success",
        confirmError: "billing:card:confirm:error",
        authorizeStart: "billing:card:authorize:start",
        authorizeSuccess: "billing:card:authorize:success",
        authorizeError: "billing:card:authorize:error",
        setupStart: "billing:card:setup:start",
        setupSuccess: "billing:card:setup:success",
        setupError: "billing:card:setup:error",
        show: "billing:card:show",
        hide: "billing:card:hide",
        disable: "billing:card:disable",
        enable: "billing:card:enable"
      }
    }
    return this._events
  }

  constructor(parent) {
    this.parent = parent // form element
    this.enabled = true

    this.element = this.parent.querySelector(this.constructor.selectors.element)
    this.eventElement = this.element
    if (!this.element) {
      throw new Error("Billing card element not found")
    }

    if (this.element["_billing"]) {
      throw new Error("Billing card element already initialized")
    }
    this.element["_billing"] = this

    const publishableKey = this.element.dataset.billingPublishableKey
    if (!publishableKey) {
      throw new Error("Billing publishable key is missing")
      return
    }
    loadStripe(publishableKey).then((stripe) => {
      this.stripe = stripe

      // https://stripe.com/docs/js/elements_object/create_element?type=card
      this.stripeElement =
        this.stripe.elements().create("card", this.elementOptions)
      this.stripeElement.mount(this.element)
      this.stripeElement.on("change", (...args) => this.handleChange(...args))
      this.stripeElementError = false

      // Initialize after 'this.stripeElement' is set.
      this.details = new CardDetails(this)
      this.details.on("change", (event) => {
        // Clear errors only if stripe element has no inline errors.
        if (!this.stripeElementError) {
          this.error.clear()
        }
      })
    })

    this.error = new BillingError(this.parent)

    this.wrapper = new CardWrapper(this.parent)
    this.wrapper.on("show", () => {
      if (this.container.visible) {
        this.error.show() // Shown only if error message set.
        this.fire("show")
      }
    })
    this.wrapper.on("hide", () => {
      this.error.hide()
      this.fire("hide")
    })

    this.container = new CardContainer(this.parent)
    this.container.on("show", () => {
      if (this.wrapper.visible) {
        this.error.show() // Shown only if error message set.
        this.fire("show")
      }
    })
    this.container.on("hide", () => {
      this.error.hide()
      this.fire("hide")
    })
  }

  submit(clientSecret) {
    this.fire("submitStart")

    return new Promise((resolve, reject) => {
      this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.stripeElement,
          billing_details: this.details.toObject()
        },
        // 'setup_future_usage' ensures card is attached to the customer.
        // This is the default behavior in almost all the cases, except in this
        // scenario (reason why we specify it explicitly):
        //
        // - Subscription is past due 'requires_payment_method'.
        // - Related PaymentIntent 'setup_future_usage' is nil.
        // - The user sets up a new card. Card setup is done with the
        //   above-mentioned PaymentIntent ('setup_future_usage' = nil).
        setup_future_usage: "off_session"
      }).then((result) => {
        if (result.error) {
          this.error.set(result.error.message)
          this.fire("submitError")
          reject()

        } else if (result.paymentIntent &&
          result.paymentIntent.status === "succeeded") {
          this.fire("submitSuccess")
          resolve()

        } else {
          this.fire("submitError")
          throw new Error("invalid Card.submit result")
        }
      })
    })
  }

  confirm(clientSecret, paymentMethod) {
    this.fire("confirmStart")

    return new Promise((resolve, reject) => {
      this.stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod
      }).then((result) => {
        if (result.error) {
          this.error.set(result.error.message)
          this.fire("confirmError")
          reject()

        } else if (result.paymentIntent &&
          result.paymentIntent.status === "succeeded") {
          this.fire("confirmSuccess")
          resolve()

        } else {
          this.fire("confirmError")
          throw new Error("invalid Card.confirm result")
        }
      })
    })
  }

  setup(clientSecret) {
    this.fire("setupStart")

    return new Promise((resolve, reject) => {
      this.stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.stripeElement,
          billing_details: this.details.toObject()
        }
      }).then((result) => {
        if (result.error) {
          this.error.set(result.error.message)
          this.fire("setupError")
          reject()

        } else if (result.setupIntent &&
          result.setupIntent.status === "succeeded") {
          this.fire("setupSuccess")
          resolve()

        } else {
          this.fire("setupError")
          throw new Error("invalid Card.setup result")
        }
      })
    })
  }

  authorize(clientSecret) {
    this.fire("authorizeStart")

    return new Promise((resolve, reject) => {
      this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.stripeElement,
          billing_details: this.details.toObject()
        },
        setup_future_usage: "off_session"
      }).then((result) => {
        if (result.error) {
          this.error.set(result.error.message)
          this.fire("authorizeError")
          reject()

        } else if (result.paymentIntent &&
          result.paymentIntent.status === "requires_capture") {
          this.fire("authorizeSuccess")
          resolve()

        } else {
          this.fire("authorizeError")
          throw new Error("invalid Card.authorize result")
        }
      })
    })
  }

  // error handling
  handleChange(event) {
    if (event.error) {
      this.stripeElementError = true
      this.error.set(event.error.message)
    } else {
      this.stripeElementError = false
      this.error.clear()
    }
  }

  // show/hide logic
  get visible() {
    return this.wrapper.visible && this.container.visible
  }

  get hidden() {
    return !this.visible
  }

  show() {
    this.wrapper.show()
    this.container.show()
    this.error.show()
    // fire("show") triggered in init()
  }

  hide() {
    this.wrapper.hide()
    this.container.hide()
    this.error.hide()
    // fire("hide") triggered in init()
  }

  // disable
  get disabled() {
    return !this.enabled
  }

  disable() {
    if (this.enabled) {
      this.enabled = false
      this.stripeElement.update({
        disabled: true,
        ...this.disabledElementOptions
      })
      this.fire("disable")
    }
  }

  enable() {
    if (this.disabled) {
      this.enabled = true
      this.stripeElement.update({
        disabled: false,
        ...this.elementOptions
      })
      this.fire("enable")
    }
  }

  // element options
  get elementOptions() {
    if (this._elementOptions === undefined) {
      this._elementOptions = {...billingCardElementOptions}
      this._elementOptions.classes = {...this._elementOptions.classes}
      delete this._elementOptions.classes.disable
    }

    return this._elementOptions
  }

  get disabledElementOptions() {
    if (this._disabledElementOptions === undefined) {
      this._disabledElementOptions = {...this.elementOptions}
      this._disabledElementOptions.classes = {
        ...this._disabledElementOptions.classes,
        base: (this._disabledElementOptions.classes.base + " " +
          billingCardElementOptions.classes.disable)
      }
    }

    return this._disabledElementOptions
  }
}

Object.defineProperties(
  Card.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
)

export default Card
