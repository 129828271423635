class FormAction {
  static get selectors() {
    if (this._selectors === undefined) {
      this._selectors = {
        element: "[data-billing-form-action]"
      }
    }
    return this._selectors
  }

  constructor(form) {
    this.formElement = form.element

    this.init()
  }

  // Form action is used *only* after a successful form submit.
  //
  // Remove any form action elements from the DOM when the form initializes.
  // (An unused form action element may be present when using turbo, and
  // rendering subscribable#edit controller action.)
  init() {
    const element = this.element
    if (element) {
      element.remove()
    }
  }

  get element() {
    return this.formElement.querySelector(this.constructor.selectors.element)
  }

  // NOTE: reads/extractions are destructive. That way form can be submitted
  // multiple times, and different action can be handled.
  extract() {
    const element = this.element // may be null, will raise in _extractValidate
    const value = this._extractValidate(element)
    element.remove()
    return value
  }

  _extractValidate(element) {
    if (!element) {
      throw new Error("billing form action element not found")
    }

    const raw = element.dataset.billingFormAction
    if (!raw) {
      throw new Error("billing form action has empty value")
    }

    let value
    try {
      value = JSON.parse(raw)
    } catch {
      throw new Error("billing form action has invalid JSON value")
    }

    if (!value.hasOwnProperty("status")) {
      throw new Error(
        "billing form action is invalid - missing 'status' key"
      )
    }
    return value
  }
}

export default FormAction
